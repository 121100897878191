import clsx from "clsx"

export default function Hero({ children, className, bgColor = 'bg-okid-gray-light' }) {
  return (
    <div className={clsx('flex flex-col w-full h-[440px] bg-no-repeat bg-cover bg-center', className)}>
      <div className="flex justify-between items-center grow lg:max-w-7xl lg:mx-auto lg:px-6">
        { children }
      </div>
      <div className="h-5 bg-stone-200"></div> 
    </div>
  )
}