import Content from "components/ui/content"
import Hero from "components/ui/hero"
import { Button } from "components/ui/button"
import { Link } from "react-router-dom"
import BackTo from "components/ui/backTo"
import { useTranslation, Trans } from "react-i18next"
import { pathWithLocale } from "lib/utils"

export default function GetId() {

  const { t } = useTranslation();

  return (
    <div>
      <Hero className="bg-[#bee7ed]">
        <div className="flex flex-col h-full justify-between mx-auto">
          <div className="flex justify-center text-okid-cyan-dark mt-12">
            <h2 className="text-4xl font-bold mr-4">
              <span className="md:hidden">{ t('getid_content_hero_title_short') }</span>
              <span className="hidden md:block">{ t('getid_content_hero_title') }</span>
            </h2>
            <div className="text-4xl font-bold text-okid-red">
              4
            </div>
          </div>
          <div className="flex justify-center w-full mb-12">
            <img src="/images/getid_cover.png" alt="get id cover" />
          </div>
        </div>
      </Hero>
      <Content className="text-okid-cyan-dark">
        <BackTo path="/">{ t('common_back_to_home') }</BackTo>
        <h1 className="text-3xl font-bold mb-4">
          { t('getid_content_get_your_id') }
        </h1>
        <p className="text-lg mb-4">
          { t('getid_content_get_your_id_sub') }
        </p>
        <div className="px-12 py-8 bg-white rounded-xl shadow-xl">
          <ul>
            <li className="mb-4">
              <h3 className="text-xl font-semibold mb-2">{ t('getid_content_get_your_id_card_item1') }</h3>
              <p className="text-lg ml-5">
                <Trans
                  i18nKey="getid_content_get_your_id_card_item1_text"
                  // eslint-disable-next-line
                  components={{ a: <a />}}
                />
              </p>
            </li>
            <li className="mb-4">
              <h3 className="text-xl font-semibold mb-2">{ t('getid_content_get_your_id_card_item2') }</h3>
              <p className="text-lg ml-5">{ t('getid_content_get_your_id_card_item2_text') }</p>
            </li>
            <li className="mb-4">
              <h3 className="text-xl font-semibold mb-2">{ t('getid_content_get_your_id_card_item3') }</h3>
              <p className="text-lg ml-5">{ t('getid_content_get_your_id_card_item3_text') }</p>
              <Button asChild className="bg-okid-green hover:bg-green-700 text-lg ml-5 mt-5">
                <Link to={pathWithLocale('/vote')}>
                  { t('common_learn_more') }
                </Link>
              </Button>
            </li>
          </ul>
        </div>
        <p className="text-lg mb-2 mt-10">
          { t('getid_content_get_your_id_by_joining') }
        </p>
      </Content>
    </div>
  )
}