import { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import { setLanguage } from "lib/utils";

export default function Layout({ lang }) {

  useEffect(() => {
    console.log('layout mounted with lang: ', lang);
    setLanguage(lang);
  }, [])

  return (
    <>
      <Navbar lang={lang} />
      <main className="min-h-screen">
        <Outlet />
      </main>
      <Footer />
    </>
  )
}