import BackTo from "components/ui/backTo"
import Content from "components/ui/content"
import { useTranslation } from "react-i18next"

export default function NotFound() {

  const { t } = useTranslation();

  return (
    <div className="h-full">
      <Content className="text-okid-cyan-dark">
        <BackTo path="/">{ t('common_back_to_home') }</BackTo>
        <h1 className="text-3xl">{ t('common_page_not_found') }</h1>
      </Content>
    </div>
  )
}