import Content from "components/ui/content"

export default function Policies() {
  return (
    <Content className="text-okid-cyan-dark">
      <div>
        Terms of Use
        Accessibility Policy
        Privacy Policy
      </div>
    </Content>
  )
}