import Hero from "components/ui/hero"
import BackTo from "components/ui/backTo"
import Content from "components/ui/content"
import {
  Card,
  CardHeader,
  CardTitle,
} from "components/ui/card"
import { Badge } from "components/ui/badge"
import { useTranslation, Trans } from "react-i18next"

export default function Results() {

  const { t } = useTranslation();

  return (
    <div>
      <Hero className="bg-[#bee7ed]">
        <div className="flex flex-col h-full justify-between mx-auto">
          <div className="flex justify-center text-okid-cyan-dark mt-12">
            <h2 className="text-4xl font-bold mr-4">
              <span>{ t('results_hero_title') }</span>
            </h2>
          </div>
          <div className="flex justify-center w-full mb-12">
            <img src="/images/results_cover.png" alt="get id cover" />
          </div>
        </div>
      </Hero>
      <Content className="text-okid-cyan-dark">
        <BackTo path="/">{ t('common_back_to_home') }</BackTo>
        <h1 className="text-3xl font-bold mb-4">
          { t('results_content_community_results') }
        </h1>
        <p className="text-lg mb-8">
          { t('results_content_community_results_sub') }.
        </p>
        <h2 className="text-3xl font-bold mb-8">
          { t('results_content_community_results_active') }
        </h2>
        <Card className="px-6 py-3 shadow-lg border-0 mb-8">
          <CardHeader>
            <CardTitle className="text-okid-cyan-dark text-xl">{ t('common_nothing_was_found') }</CardTitle>
          </CardHeader>
        </Card>
        <h2 className="text-3xl font-bold mb-8">
          { t('results_content_community_results_completed') }
        </h2>
        <Card className="px-6 py-3 shadow-lg border-0">
          <CardHeader>
            <CardTitle className="text-okid-cyan-dark text-xl">{ t('common_nothing_was_found') }</CardTitle>
          </CardHeader>
        </Card>
      </Content>
      <Content className="text-okid-cyan-dark" bgColor="bg-stone-200">
        <div className="mb-4">
          <h2 className="text-3xl font-bold mb-5">
            { t('results_content_community_results_how_to') }
          </h2>
          <ul>
            <li className="mb-5">
              { t('results_content_community_results_how_to_1') }
            </li>
            <li className="mb-3">
              <Trans 
                i18nKey="results_content_community_results_how_to_2"
                components={{ Badge: <Badge /> }}
              />
            </li>
            <li className="mb-3">
              <Trans 
                i18nKey="results_content_community_results_how_to_3"
                components={{ Badge: <Badge /> }}
              />
            </li>
          </ul>
        </div>
      </Content>
      <Content className="text-okid-cyan-dark">
        <p className="text-lg mb-2 mt-4">
          { t('results_content_community_results_you_continued') }
        </p>
      </Content>
    </div>
  )
}