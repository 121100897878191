import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import * as SocialIconsComponent from '../components/social';
import i18n from "i18n";

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export function getSocialIconComponent( { name, props } ) {
	const ComponentsMap = {
    twitter: SocialIconsComponent.Twitter,
		threads: SocialIconsComponent.Threads,
		instagram: SocialIconsComponent.Instagram,
	}

	if ( name in ComponentsMap ) {
		const SocialIconComponent = ComponentsMap[name];
		return <SocialIconComponent {...props} />;
	} else {
		return null
	}
}

export function useOrigin() {
	return process.env.NODE_ENV === "development"
	? "http://localhost:3000"
	: "https://id.freekalmykia.org";
}

export async function setLanguage(lang) {
	return await i18n.changeLanguage(lang);
}

export function getLanguage() {
	return i18n.language;
}

export function pathWithoutLocale(pathname) {
	return pathname.split('/')
		.filter(item => !['ok', 'en', 'ru'].includes(item))
		.join('/')
}

export function pathWithLocale(pathname) {
	const lang = i18n.language;
	const langPath = lang === 'en' ? '' : `/${lang}`;
	return `${langPath}${pathname}`;
}