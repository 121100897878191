export const American = ({ className }) => {
  return (
    <span>
      <img className={className} src="/images/usa_flag.jpg" alt="American Flag" />
    </span>
  )
}

export const OiradKalmyk = ({ className }) => {
  return (
    <span>
      <img className={className} src="/images/kalmykia_flag.jpg" alt="American Flag" />
    </span>
  )
}

export const Russian = ({ className }) => {
  return (
    <span>
      <img className={className} src="/images/russian_flag.jpg" alt="American Flag" />
    </span>
  )
}