import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "layout/Layout";
import Home from "pages/Home";
import GetId from "pages/GetId";
import Vote from "pages/Vote";
import Results from "pages/Results";
import Policies from "pages/Policies";
import ScrollToTop from "components/ui/ScrollToTop";

import "./i18n";
import NotFound from "pages/NotFound";

function App() {

  return (
    <div className="App relative min-h-screen bg-okid-gray-light">
      <Suspense fallback="loading">
        <ScrollToTop />
        <Routes>
          {
            ['ok', 'en', 'ru'].map(lang => (
              <Route path={ lang === 'en' ? '/' : lang } element={<Layout lang={lang} />}>
                <Route path="" element={<Home />} />
                <Route path="getid" element={<GetId />} />
                <Route path="vote" element={<Vote />} />
                <Route path="results" element={<Results />} />
                <Route path="policies" element={<Policies />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            ))
          }
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
