import { useState } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom"
import clsx from "clsx"
import { Disclosure } from "@headlessui/react"
import { useTranslation } from "react-i18next"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select"
import { OiradKalmyk, American, Russian } from "components/flags"
import { setLanguage, pathWithLocale, pathWithoutLocale } from "lib/utils"


export default function Navbar({ lang: lng }) {

  const navigate = useNavigate();

  const [lang, setLang] = useState(lng || 'en');

  const location = useLocation();
  const { t } = useTranslation();

  const menu = [
    {
      name: t('navbar_home'),
      link: '/'
    },
    {
      name: t('navbar_getid'),
      link: '/getid'
    },
    {
      name: t('navbar_vote'),
      link: '/vote'
    },
    {
      name: t('navbar_results'),
      link: '/results'
    },
  ];

  const changeLang = async lang => {
    await setLanguage(lang);
    setLang(lang);
    navigate(pathWithLocale(pathWithoutLocale(location.pathname)));
  }

  return (
    <>
      <Disclosure as="header" className={clsx('relative bg-okid-gray-light')}>
        {({ open }) => (
          <>
            <nav className="flex flex-col xs:flex-row justify-center items-center h-32 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 py-5">
              
              {/* Main navbar for large screens */}
              <div className="flex h-full lg:h-[72px] items-center justify-between w-full">

                {/* Logo */}
                <div className="flex h-14 lg:h-[72px] items-center shrink-0">
                  <img className="lg:hidden" src="/images/logo_title_short.png" alt="Logo" height={72} />
                  <img className="hidden lg:block" src="/images/logo_title.png" alt="Logo" height={72} />
                </div>

                <div className="flex flex-col items-end">
                  {/* Language Selector */}
                  <div className="flex items-center mb-3">
                    <span className="text-base font-semibold mr-4 hidden sm:block">{ t('navbar_select_lang') }</span>
                    <Select value={lang} onValueChange={changeLang}>
                      <SelectTrigger className="h-8 w-[70px] py-0 px-2">
                        <SelectValue placeholder="Theme" />
                      </SelectTrigger>
                      <SelectContent className="min-w-12">
                        <SelectItem className="h-12" value="ok">
                          <OiradKalmyk className="w-12" />
                        </SelectItem>
                        <SelectItem className="h-12" value="en">
                          <American className="w-12" />
                        </SelectItem>
                        <SelectItem className="h-12" value="ru">
                          <Russian className="w-12" />
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  {/* Navigation for large screens */}
                  <div className="flex items-center">
                    <div className="xs:flex hidden">
                      <div className="ml-6 hidden md:flex justify-between items-center md:space-x-0.5 lg:space-x-2 text-xl md:text-base">

                        {menu.map((link, index) =>
                          (
                            <Link
                              key={index}
                              to={pathWithLocale(link.link)}
                              className={`px-3 py-1 font-medium text-md ${location.pathname === pathWithLocale(link.link) ? 'text-okid-red' : 'text-okid-cyan-dark transition duration-300 ease-in-out hover:text-red-700'}`}
                            >
                                {link.name}
                            </Link>
                          )
                        )}

                      </div>
                    </div>

                    {/* Hamburger menu button */}
                    <Disclosure.Button className="flex items-center justify-center p-3 ml-6 transition duration-300 ease-in-out cursor-pointer rounded-xl bg-gray-50 hover:bg-gray-200 md:hidden group focus:outline-none">
                      <span className={`relative w-4 h-3.5 transition duration-500 ease-in-out transform rotate-0 ${open ? 'js-hamburger-open' : ''}`}>
                        <span className="absolute top-0 left-0 block w-full h-0.5 transition duration-300 ease-in-out transform rotate-0 bg-gray-600 rounded-full opacity-100 group-hover:bg-gray-900" />
                        <span className="absolute left-0 block w-full h-0.5 transition duration-300 ease-in-out transform rotate-0 bg-gray-600 rounded-full opacity-100 top-1.5 group-hover:bg-gray-900" />
                        <span className="absolute left-0 block w-full h-0.5 transition duration-300 ease-in-out transform rotate-0 bg-gray-600 rounded-full opacity-100 top-1.5 group-hover:bg-gray-900" />
                        <span className="absolute left-0 block w-full h-0.5 transition duration-300 ease-in-out transform rotate-0 bg-gray-600 rounded-full opacity-100 top-3 group-hover:bg-gray-900" />
                      </span>
                    </Disclosure.Button>
                  </div>
                </div>
              </div>
            </nav>

            {/* Mobile menu */}
            <Disclosure.Panel>
              {({ close }) => (
                <nav className=" md:hidden" aria-label="Global" id="mobile-menu">
                  <div className="px-2 pt-2 pb-3 space-y-1">

                    {menu.map((link, i) =>
                      (
                        <Link
                          key={i}
                          to={pathWithLocale(link.link)}
                          onClick={close}
                          className={`block px-4 py-3 font-medium rounded-lg ${location.pathname === pathWithLocale(link.link) ? 'bg-gray-300 text-red-700' : 'text-gray-800 hover:bg-gray-200 hover:text-red-700 transition duration-300 ease-in-out'}`}
                        >
                            {link.name}
                        </Link>
                      )
                    )}

                  </div>
                </nav>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="h-5 bg-stone-200"></div>
    </>
  )
}