import clsx from "clsx"

export default function Content({ children, className, bgColor = 'bg-okid-gray-light' }) {
  return (
    <div className={clsx('w-full', bgColor, className)}>
      <div className="max-w-7xl mx-auto px-6 py-10">
        { children }
      </div>
    </div>
  )
}