import { Link } from "react-router-dom";
import { getSocialIconComponent } from "lib/utils";
import siteConfig from '../config/site.config'
import { useTranslation } from "react-i18next"
import { pathWithLocale } from "lib/utils";

const socialLinks = [
  {
    name: 'instagram',
    href: 'https://www.instagram.com/freekalmykia'
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/FreeKalmykia'
  },
  // {
  //   name: 'threads',
  //   href: 'https://threads.net/@freekalmykia'
  // },
]

export default function Footer() {

  const { t } = useTranslation();

  const footerLinks = [
    {
      name: t('navbar_home'),
      link: '/'
    },
    {
      name: t('navbar_getid'),
      link: '/getid'
    },
    {
      name: t('navbar_vote'),
      link: '/vote'
    },
    {
      name: t('navbar_results'),
      link: '/results'
    },
    {
      "name": t('common_privacy_policy'),
      "link": "/policies"
    },
    {
      "name": t('common_accessibility_policy'),
      "link": "/policies"
    },
    {
      "name": t('common_terms_of_use'),
      "link": "/policies"
    }
  ]

  return (
    <footer className="py-12 bg-white sm:py-20 lg:py-24">
      <div className="max-w-2xl px-4 mx-auto lg:max-w-screen-xl sm:px-6 lg:px-8">

        {/* Logo & Social Links */}
        <div className="sm:flex sm:justify-between sm:items-center">
        
          {/* Logo */}
          {/* <div className="flex items-center justify-center">
            <Link href="/">
              <a className="block h-12">
                <Image 
                  src={siteConfig.logoFull} 
                  alt={siteConfig.logoText}
                  width={308}
                  height={48}
                  layout="fixed"
                  placeholder="blur"
                  blurDataURL={siteConfig.logoFull}
                  className="w-auto h-12"
                />
              </a>
            </Link>
          </div> */}

          {/* Social Links*/}
          <div className="flex items-center justify-center mt-6 sm:mt-0">
            <ul className="flex items-center space-x-3 sm:ml-4">
              {socialLinks.map((item) => (
                <li key={item.name} >
                  <a href={item.href} className="flex items-center justify-center w-10 h-10 transition duration-300 ease-in-out bg-transparent border rounded-full border-gray-300/70 sm:w-12 sm:h-12 group">
                    <span className="sr-only">{item.name}</span>
                    { getSocialIconComponent({
                      name: item.name, 
                      props: { 
                        className: "w-3.5 h-3.5 text-gray-700 transition duration-300 ease-in-out transform group-hover:text-red-700 group-hover:scale-110" 
                      }
                    }) }
                  </a>
                </li>
              ))}
            </ul>
          </div>

        </div>

        <div className="text-center mt-4">
          <p>{t('common_contact_us')} <a className="text-blue-600 hover:text-blue-400 underline hover:no-underline" href="mailto:board@freekalmykia.org">board@freekalmykia.org</a></p>
        </div>

        {/* Footer Links Container */}
        <div className="pt-10 mt-10 border-t md:flex md:justify-between md:items-center border-t-gray-300/70">
          
          {/* Footer Links */}
          <nav className="flex flex-wrap items-center justify-center -mx-5 -my-2 md:justify-start" aria-label="Footer">
            {footerLinks.map((item) => (
              <Link key={item.name} className="px-5 py-2 text-base text-gray-500 transition duration-300 ease-in-out hover:text-red-700" to={pathWithLocale(item.link)}>
                  {item.name}
              </Link>
            ))}
          </nav>

          {/* Copyright Text */}
          <p className="flex items-center justify-center mt-8 ml-0 text-base text-gray-400 md:ml-6 shrink-0 md:mt-0">
            {siteConfig.copyright}
          </p>
        </div>
      </div>
    </footer>

  )
}