import Hero from "components/ui/hero"
import BackTo from "components/ui/backTo"
import { Button } from "components/ui/button"
import { Link } from "react-router-dom"
import Content from "components/ui/content"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ui/card"
import { Badge } from "components/ui/badge"
import { useTranslation, Trans } from "react-i18next"
import { pathWithLocale } from "lib/utils"

export default function Vote() {

  const { t } = useTranslation();

  return (
    <div>
      <Hero className="bg-[#bee7ed]">
        <div className="flex flex-col h-full justify-between mx-auto">
          <div className="flex justify-center text-okid-cyan-dark mt-12">
            <h2 className="text-4xl font-bold mr-4">
              <span>{ t('vote_hero_title') }</span>
            </h2>
            <div className="text-4xl font-bold text-okid-red">
              0
            </div>
          </div>
          <div className="flex justify-center w-full mb-12">
            <img src="/images/vote_cover.png" alt="get id cover" />
          </div>
        </div>
      </Hero>
      <Content className="text-okid-cyan-dark">
        <BackTo path="/">{ t('common_back_to_home') }</BackTo>
        <h1 className="text-3xl font-bold mb-4">
          { t('vote_content_vote_on') }
        </h1>
        <p className="text-lg mb-4">
          { t('vote_content_vote_on_sub') }
        </p>
        <h2 className="text-3xl font-bold mb-4">
          { t('vote_content_vote_initiative') }
        </h2>
        <Card className="px-6 py-3 shadow-lg border-0">
          <CardHeader>
            <CardTitle className="text-okid-cyan-dark">Road Map to Oirad-Kalmykia Independence</CardTitle>
          </CardHeader>
          <CardContent className="text-okid-cyan-dark text-lg">
            <div>
              <Badge className="bg-gray-300 hover:bg-gray-300 text-sm text-black px-4 py-1 mb-4">Draft</Badge>
              <div className="mb-4">
                <p>The "Road Map to Oirad-Kalmykia Independence" is a comprehensive plan designed to outline the strategic steps and milestones toward achieving the long-term goal of Oirad-Kalmykia's independence. This document serves as a guiding framework, providing a clear and structured path that the community envisions for the future of our homeland.</p>
              </div>
              <Button asChild className="bg-gray-300 hover:bg-gray-400 text-lg mb-4">
                <Link to={pathWithLocale('/vote')} className="pointer-events-none">
                  Vote
                </Link>
              </Button>
              <div className="flex flex-wrap">
                <div className="flex mr-6 items-center mt-4">
                  <span className="text-base font-bold mr-4">YES:</span>
                  <Badge className="bg-green-400 hover:bg-green-500 text-base text-black px-4 py-1">0%</Badge>
                </div>
                <div className="flex mr-6 items-center mt-4">
                  <span className="text-base font-bold mr-4">NO:</span>
                  <Badge className="bg-red-400 hover:bg-red-500 text-base text-black px-4 py-1">0%</Badge>
                </div>
                <div className="flex mr-6 items-center mt-4">
                  <span className="text-base font-bold mr-4">Voter turnout:</span>
                  <Badge className="bg-yellow-400 hover:bg-yellow-500 text-base text-black px-4 py-1">0%</Badge>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Content>
      <Content className="text-okid-cyan-dark" bgColor="bg-stone-200">
        <div className="mb-4">
          <h2 className="text-3xl font-bold mb-5">
            { t('vote_content_vote_how_to') }
          </h2>
          <ul>
            <li className="mb-5">
              { t('vote_content_vote_how_to_text1') }
            </li>
            <li className="mb-3">
              <Trans 
                i18nKey="vote_content_vote_how_to_text2"
                components={{ Badge: <Badge /> }}
              />
            </li>
            <li className="mb-3">
              <Trans 
                i18nKey="vote_content_vote_how_to_text3"
                components={{ Badge: <Badge /> }}
              />
            </li>
            <li className="mb-3">
              <Trans 
                i18nKey="vote_content_vote_how_to_text4"
                components={{ Badge: <Badge /> }}
              />
            </li>
            <li className="mb-3">
              <Trans 
                i18nKey="vote_content_vote_how_to_text5"
                components={{ Badge: <Badge /> }}
              />
            </li>
            <li className="mb-3">
              <Trans 
                i18nKey="vote_content_vote_how_to_text6"
                components={{ Badge: <Badge /> }}
              />
            </li>
          </ul>
        </div>
      </Content>
      <Content className="text-okid-cyan-dark">
        <p className="text-lg mb-2 mt-4">
          { t('vote_content_your_vote_shapes') }
        </p>
      </Content>
    </div>
  )
}