import { Button } from "components/ui/button"
import { Link } from "react-router-dom"
import Hero from "components/ui/hero"
import Content from "components/ui/content"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ui/card"
import {
  Link as LinkIcon,
  Home as HomeIcon,
  Send as SendIcon,
  Vote as VoteIcon
} from "lucide-react"
import { Badge } from "components/ui/badge"
import { useTranslation, Trans } from "react-i18next"
import { pathWithLocale } from "lib/utils"

export default function Home() {

  const { t } = useTranslation();

  return (
    <div>
      <Hero className="bg-hero-bg-1 h-[510px]">
        <div className="bg-okid-blue/[.7] w-full md:w-3/5 h-[430px] p-6">
            <h1 className="text-3xl md:text-4xl font-bold text-white mb-5">
              { t('home_hero_title') }
            </h1>
            <h2 className="text-xl md:text-2xl font-semibold italic text-white mb-6">
              { t('home_hero_desc') }
            </h2>
            <Button asChild className="bg-okid-red hover:bg-red-600 text-lg">
              <Link to={pathWithLocale('/getid')}>
                { t('home_hero_action') }
              </Link>
            </Button>
          </div>
          <div className="hidden md:block w-2/5 h-80">
            <div className="flex flex-col h-full justify-between px-10">
              <div className="hover:-translate-y-1.5">
                <Link to={pathWithLocale('/getid')}><img src="/images/male_id.png" alt="male id" /></Link>
              </div>
              <div className="hover:-translate-y-1.5">
                <Link to={pathWithLocale('/getid')}><img src="/images/female_id.png" alt="female id" /></Link>
              </div>
            </div>
          </div>
      </Hero>
      <Content className="text-okid-cyan-dark">
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-3">
            { t('home_content_first_about_oirad_kalmykia') }
          </h2>
          <p className="text-lg mb-4">
            { t('home_content_first_about_oirad_kalmykia_text1') }
          </p>
          <p className="text-lg">
            { t('home_content_first_about_oirad_kalmykia_text2') }
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-3">
            { t('home_content_our_vision') }
          </h2>
          <p className="text-lg">
            { t('home_content_our_vision_text') }
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-3">
            { t('home_content_why_system_matters') }
          </h2>
          <h3 className="text-xl font-bold mb-3">
            { t('home_content_why_system_matters_sub1') }
          </h3>
          <p className="text-lg">
            <Trans
              i18nKey='home_content_why_system_matters_text1'
              components={{ span: <span /> }}
            />
          </p>
          <h3 className="text-xl font-bold my-3">
            { t('home_content_why_system_matters_sub2') }
          </h3>
          <p className="text-lg">
            { t('home_content_why_system_matters_text2') }
          </p>
        </div>
        <div className="mb-8">
          <h2 className="text-3xl font-bold mb-3">
            { t('home_content_how_it_works') }
          </h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <Card className="flex px-6 pb-3 shadow-lg border-0">
            <div className="flex flex-col justify-start">
              <div className="py-6 px-4">
                <span className="text-4xl font-bold">1</span>
              </div>
              <div className="bg-blue-50 p-4 rounded-xl">
                <LinkIcon />
              </div>
            </div>
            <div>
              <CardHeader className="pt-8">
                <CardTitle className="text-okid-cyan-dark">{ t('home_content_how_it_works_1_card_title') }</CardTitle>
              </CardHeader>
              <CardContent className="text-okid-cyan-dark text-lg">
                <p>{ t('home_content_how_it_works_1_card_text') }</p>
              </CardContent>
            </div>
          </Card>
          <Card className="flex px-6 pb-3 shadow-lg border-0">
            <div className="flex flex-col justify-start">
              <div className="py-6 px-4">
                <span className="text-4xl font-bold">2</span>
              </div>
              <div className="bg-red-50 p-4 rounded-xl">
                <HomeIcon />
              </div>
            </div>
            <div>
              <CardHeader className="pt-8">
                <CardTitle className="text-okid-cyan-dark">{ t('home_content_how_it_works_2_card_title') }</CardTitle>
              </CardHeader>
              <CardContent className="text-okid-cyan-dark text-lg">
                <p>{ t('home_content_how_it_works_2_card_text') }</p>
              </CardContent>
            </div>
          </Card>
          <Card className="flex px-6 pb-3 shadow-lg border-0">
            <div className="flex flex-col justify-start">
              <div className="py-6 px-4">
                <span className="text-4xl font-bold">3</span>
              </div>
              <div className="bg-green-50 p-4 rounded-xl">
                <SendIcon />
              </div>
            </div>
            <div>
              <CardHeader className="pt-8">
                <CardTitle className="text-okid-cyan-dark">{ t('home_content_how_it_works_3_card_title') }</CardTitle>
              </CardHeader>
              <CardContent className="text-okid-cyan-dark text-lg">
                <p>{ t('home_content_how_it_works_3_card_text') }</p>
              </CardContent>
            </div>
          </Card>
          <Card className="flex px-6 pb-3 shadow-lg border-0">
            <div className="flex flex-col justify-start">
              <div className="py-6 px-4">
                <span className="text-4xl font-bold">4</span>
              </div>
              <div className="bg-yellow-50 p-4 rounded-xl">
                <VoteIcon />
              </div>
            </div>
            <div>
              <CardHeader className="pt-8">
                <CardTitle className="text-okid-cyan-dark">{ t('home_content_how_it_works_4_card_title') }</CardTitle>
              </CardHeader>
              <CardContent className="text-okid-cyan-dark text-lg">
                <p>{ t('home_content_how_it_works_4_card_text') }</p>
              </CardContent>
            </div>
          </Card>
        </div>
        <div className="my-8">
          <h2 className="text-3xl font-bold mb-3">
            { t('home_content_looking_for_id') }
          </h2>
          <p className="text-xl font-bold mb-4">
            { t('home_content_looking_for_id_sub') }
          </p>
          <div className="px-12 py-8 bg-white rounded-xl shadow-xl">
            <ul>
              <li className="mb-4">
                <h3 className="text-xl font-semibold mb-2">{ t('home_content_looking_for_id_card_item1') }</h3>
                <p className="text-lg ml-5">{ t('home_content_looking_for_id_card_item1_text') }</p>
              </li>
              <li className="mb-4">
                <h3 className="text-xl font-semibold mb-2">{ t('home_content_looking_for_id_card_item2') }</h3>
                <p className="text-lg ml-5">{ t('home_content_looking_for_id_card_item2_text') }</p>
              </li>
              <li className="mb-2">
                <h3 className="text-xl font-semibold mb-2">{ t('home_content_looking_for_id_card_item3') }</h3>
                <p className="text-lg ml-5">
                  <Trans
                    i18nKey="home_content_looking_for_id_card_item3_text"
                    // eslint-disable-next-line
                    components={{ a: <a /> }}
                  />
                </p>
              </li>
            </ul>
          </div>
        </div>
      </Content>
      <Content className="text-okid-cyan-dark" bgColor="bg-stone-200">
        <div className="mb-4">
          <h2 className="text-3xl font-bold mb-4">
            { t('home_content_shape_our_community') }
          </h2>
          <p className="text-2xl font-bold pt-4 mb-4">
            { t('home_content_shape_our_community_text') }
          </p>
        </div>
      </Content>
      <Content className="text-okid-cyan-dark">
        <h2 className="text-3xl font-bold mb-4">
          { t('home_content_looking_ready_to_vote') }
        </h2>
        <div className="px-12 py-8 bg-white rounded-xl shadow-xl">
          <ul>
            <li className="mb-4">
              <h3 className="text-xl font-semibold mb-2">{ t('home_content_looking_ready_to_vote_card_item1') }</h3>
              <p className="text-lg ml-5">{ t('home_content_looking_ready_to_vote_card_item1_text') }</p>
            </li>
            <li className="mb-4">
              <h3 className="text-xl font-semibold mb-2">{ t('home_content_looking_ready_to_vote_card_item2') }</h3>
              <p className="text-lg ml-5">{ t('home_content_looking_ready_to_vote_card_item2_text') }</p>
            </li>
          </ul>
          <Button asChild className="bg-okid-green hover:bg-green-700 text-lg ml-5">
            <Link to={pathWithLocale('/vote')}>
              { t('home_content_looking_ready_to_vote_button') }
            </Link>
          </Button>
        </div>
      </Content>
      <Content className="text-okid-cyan-dark" bgColor="bg-stone-200">
        <div className="mb-4">
          <h2 className="text-3xl font-bold mb-4">
            { t('home_content_vote_anonymously') }
          </h2>
          <ul className="text-xl font-semibold list-disc pl-4 pt-4">
              <li className="mb-4">
                <Trans
                  i18nKey='home_content_vote_anonymously_item1'
                  components={{ span: <span /> }}
                />
              </li>
              <li className="mb-4">
                <Trans
                  i18nKey='home_content_vote_anonymously_item2'
                  components={{ span: <span /> }}
                />
              </li>
              <li className="mb-4">
                <Trans
                  i18nKey='home_content_vote_anonymously_item3'
                  components={{ span: <span /> }}
                />
              </li>
            </ul>
        </div>
      </Content>
      <Content className="text-okid-cyan-dark">
        <h2 className="text-3xl font-bold mb-4">
          { t('home_content_explore_results') }
        </h2>
        <p className="text-lg font-semibold mb-4">
          <Trans
            i18nKey='home_content_explore_results_sub'
            components={{ span: <span /> }}
          />
        </p>
        <h3 className="text-3xl font-bold mb-4">
          { t('home_content_current_matters') }
        </h3>
        <Card className="px-6 py-3 shadow-lg border-0">
          <CardHeader>
            <CardTitle className="text-okid-cyan-dark">{ t('home_content_current_matters_card_title') }</CardTitle>
          </CardHeader>
          <CardContent className="text-okid-cyan-dark text-lg">
            <div>
              <Badge className="bg-gray-300 hover:bg-gray-300 text-sm text-black px-4 py-1 mb-4">{ t('common_draft') }</Badge>
              <div className="mb-4">
                <p>{ t('home_content_current_matters_card_text') }</p>
              </div>
              <Button asChild className="bg-gray-300 hover:bg-gray-400 text-lg mb-4">
                <Link to={pathWithLocale('/vote')} className="pointer-events-none">
                  { t('common_vote') }
                </Link>
              </Button>
              <div className="flex flex-wrap">
                <div className="flex mr-6 items-center mt-4">
                  <span className="text-base font-bold mr-4">{ t('common_yes').toUpperCase() }:</span>
                  <Badge className="bg-green-400 hover:bg-green-500 text-base text-black px-4 py-1">0%</Badge>
                </div>
                <div className="flex mr-6 items-center mt-4">
                  <span className="text-base font-bold mr-4">{ t('common_no').toUpperCase() }:</span>
                  <Badge className="bg-red-400 hover:bg-red-500 text-base text-black px-4 py-1">0%</Badge>
                </div>
                <div className="flex mr-6 items-center mt-4">
                  <span className="text-base font-bold mr-4">{ t('common_voter_turnout') }:</span>
                  <Badge className="bg-yellow-400 hover:bg-yellow-500 text-base text-black px-4 py-1">0%</Badge>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </Content>
    </div>
  )
}