import { Link } from "react-router-dom"
import { pathWithLocale } from "lib/utils"

export default function BackTo({ path, children }) {
  return (
    <Link to={pathWithLocale(path)} className="flex items-center mb-8 text-xl hover:text-red-700">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-left"><path d="m12 19-7-7 7-7"/><path d="M19 12H5"/></svg>
        <span className="font-semibold ml-1">{ children }</span>
      </Link>
  )
}